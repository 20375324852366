


export function getName(type) {
    if (type === 'annual') {
        return '年假';
    }
    if (type === 'personal') {
        return '事假';
    }
    if (type === 'sick') {
        return '病假'
    }
    if (type === 'marriage') {
        return '婚假'
    }
    if (type === 'maternity') {
        return '产假'
    }
    if (type === 'prenatal_check_up') {
        return '产检假'
    }
    if (type === 'paternity') {
        return '护理假'
    }
    if (type === 'breast_feeding') {
        return '哺乳假'
    }
    if (type === 'work_related_injury') {
        return '工伤假'
    }
    if (type === 'bereavement') {
        return '丧假';
    }
        if (type === 'single_child_carry') {
        return '独生子女带薪护理假';
    }
        if (type === 'childcare') {
        return '育儿假';
    }

    if (type === 'on_business_in') {
        return '公出';
    }
    if (type === 'on_business_out') {
        return '出差';
    }
    if (type === 'overtime') {
        return '加班';
    }
    if (type === 'days_off') {
        return '调休';
    }
    return '未知'
}


export function isLeapYear(year) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}