<template>
  <span>
    <span @click.stop="visible = true">
      <a-tooltip placement="top">
        <template slot="title">
          <span>我的年假</span>
        </template>
        <a-icon type="info-circle" style="color: #000" />
      </a-tooltip>
    </span>

    <a-modal title="我的年假" :visible="visible" @cancel="visible = false" :footer="null" width="800px">
      <div class="tips">
        <template v-if="!isExpired">

          去年年假额度
                  <span style="color: red">{{ lastYear.base }}</span> 天，可用
                  <span style="color: red">{{ lastYear.generated }}</span> 天，用了
                  <span style="color: red">{{ lastYear.used }}</span> 天， 还剩
                  <span style="color: red">{{
                    lastYear.left
                  }}</span>
                  天；

        </template>
        今年年假额度
        <span style="color: red">{{thisYear.base}}</span> 天，
        用了
        <span style="color: red">{{thisYear.used}}</span> 天，
        还剩
        <span style="color: red">{{thisYear.base - thisYear.used}}</span>
        天，
        由于年假不可提前申请，截止到今天，今年年假可用
        <span style="color: red">{{thisYear.generated}}</span> 天，
        已用
        <span style="color: red">{{thisYear.used}}</span> 天，
        所以你可以申请今年的年假
        <span style="color: red">{{thisYear.left}}</span> 天。
      </div>

      <a-table bordered :data-source="list" :loading="loading" rowKey="id">
        <a-table-column title="姓名" data-index="name" align="center" width="80px" />
        <a-table-column title="时长(天)" data-index="duration" align="center" width="80px" />
        <a-table-column title="开始日期" data-index="startTime" align="center" width="120px" />
        <a-table-column title="结束日期" data-index="endTime" align="center" width="120px" />
        <a-table-column title="说明" data-index="remark" />
      </a-table>
    </a-modal>
  </span>
</template>

<script>
import moment from "moment";
import { fetchYearDetail } from "@/api/human-resources/clock";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      list: [],

      lastYear: {},
      thisYear: {},
      isExpired: false
    };
  },

  watch: {
    visible() {
      if (this.visible) {
        this.getData();
      }
    }
  },

  methods: {
    getData() {
      const now = moment();
      if (now.isSameOrAfter(now.format("YYYY") + "-07-01", "days")) {
        this.isExpired = true;
      }
      this.loading = true;

      fetchYearDetail()
        .then(res => {
          console.log("year detail", res);
          const { last, current, list } = res || {};
          this.lastYear = last || {};
          this.thisYear = current || {};
          this.list = Array.isArray(list) ? list : [];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.tips {
  margin-bottom: 12px;
}
</style>