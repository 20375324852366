import { startTimeList, endTimeList } from "./time";
import { mapState, mapActions } from "vuex";

import moment from 'moment'
// 计算假期长度，因为有的假期需要跳过周末和节假日，不能直接的得到两个日期之间的差值

export default {

    computed: {
        ...mapState('holiday', ['holiday'])
    },

    mounted() {
        if (this.holiday.length === 0) {
            this.getHoliday();
        }
    },

    methods: {

        ...mapActions("holiday", ["getHoliday"]),


        getLen({
            type,
            startDate,
            startTime,
            endDate,
            endTime,

        }) {


            if (startDate && startTime && endDate && endTime) {

                console.log(startDate.format("YYYY-MM-DD"), startTime, endDate.format("YYYY-MM-DD"), endTime)

                if (endDate.isBefore(startDate)) {
                    this.$message.error('结束日期不得早于开始日期')
                    return;
                }
                if (endDate.isSame(startDate, 'day')) {

                    if (type === 'annual' || type === 'personal' || type === 'bereavement') {
                        // 年假：跳过周末和节假日
                        // 事假：跳过周末和节假日
                        // 丧假：跳过周末和节假日

                        const startDateStatus = this.isHoliday(startDate);

                        if (startDateStatus === 'holiday') {
                            // 如果是节假日，直接跳过
                            this.$message.error('请选择工作日')
                            return;
                        } else {
                            // 如果是周末，直接跳过
                            if (this.isWeekend(startDate) && startDateStatus !== 'workday') {
                                this.$message.error('请选择工作日')
                                return;
                            }
                        }


                    }


                    if (startTime === startTimeList[0].value && endTime === endTimeList[0].value) {
                        return 0.5;
                    }
                    if (startTime === startTimeList[0].value && endTime === endTimeList[1].value) {
                        return 1;
                    }
                    if (startTime === startTimeList[1].value && endTime === endTimeList[0].value) {
                        this.$message.error('结束时间不得早于开始时间')
                        return
                    }
                    if (startTime === startTimeList[1].value && endTime === endTimeList[1].value) {
                        return 0.5;
                    }

                    return;
                }


                let diff = endDate.diff(startDate, 'days');
                console.log('original diff', diff)

                if (diff > 0) {
                    return this.checkType({ type, diff, startDate, endDate, startTime, endTime });
                }


            }
        },

        // 跳过周末和节假日意味着用户选择的日期算出来的长度跟真实的长度不一致。

        checkType({ type, diff, startDate, endDate, startTime, endTime }) {
            if (type === 'annual') {
                // 年假：跳过周末和节假日
                return this.getLengthWithoutWeekend({ diff, startDate, endDate, startTime, endTime });

            }
            if (type === 'personal') {
                // 事假：跳过周末和节假日
                return this.getLengthWithoutWeekend({ diff, startDate, endDate, startTime, endTime });
            }

            if (type === 'sick') {
                // 病假：不跳过周末和节假日
                // 就是连续的10天假，公司每年有不扣钱的病假额度，如果你碰巧每次都是周五和周一生病，那你就分开请假就好了，连着周末请会占用你的免费病假额度
                return this.getLength({ diff, startTime, endTime })

            }
            if (type === 'marriage') {
                // 婚假不跳过周末和节假日
                return this.getLength({ diff, startTime, endTime })
            }
            if (type === 'maternity') {
                // 产假的逻辑不在这里做
                return this.getLengthWithoutRealHoliday({ diff, startDate, endDate, startTime, endTime })
            }
            if (type === 'prenatal_check_up') {

                // 产检假：不跳过周末和节假日
                // 反正最多一次请4天，包括了周末就让她包括去吧，反正人资管理员看到也不会扣钱
                //（正常情况下，那些员工都是请工作日，就算他们请了周末，只要在4天内就行）
                // 他们也不会傻到请周末，肯定是连请4天工作日产检
                return this.getLength({ diff, startTime, endTime });

            }
            if (type === 'paternity') {
                // 护理假不跳过周末和节假日
                return this.getLength({ diff, startTime, endTime })
            }
            if (type === 'breast_feeding') {
                // 哺乳假不跳过周末和节假日，好像是请完产假之后，到明年的今天，都算是哺乳假，程序给孕妇自动请的好像
                return;
            }

            if (type === 'bereavement') {
                // 丧假：跳过周末和节假日
                return this.getLengthWithoutWeekend({ diff, startDate, endDate, startTime, endTime });
            }

            // 独生子女带薪护理假和育儿假：不含周末和节假日，也就是跳过周末和节假日
            if (type === 'single_child_carry') {
                return this.getLengthWithoutWeekend({ diff, startDate, endDate, startTime, endTime });
            }
            if (type === 'childcare') {
                return this.getLengthWithoutWeekend({ diff, startDate, endDate, startTime, endTime });
            }

            if (type === 'on_business_in') {
                // 公出和出差 不跳过周末和节假日，这期间算是正常公出和出差
                return this.getLength({ diff, startTime, endTime })
            }
            if (type === 'on_business_out') {
                return this.getLength({ diff, startTime, endTime })
            }

            return;
        },

        getLengthWithoutWeekend({ diff, startDate, endDate, startTime, endTime }) {

            let num = 0;

            const startDateStatus = this.isHoliday(startDate);

            if (startDateStatus === 'holiday') {
                // 如果是节假日，直接跳过
                console.log(startDate.format("YYYY-MM-DD") + 'is holiday')
            } else {
                // 不是节假日，也不是加班日，就是普通的工作日，只要不是周末就获取长度
                if (!this.isWeekend(startDate) || startDateStatus === 'workday') {
                    if (startTime === startTimeList[0].value) {
                        num += 1;
                    } else if (startTime === startTimeList[1].value) {
                        num += 0.5;
                    }
                }
            }

            const endDateStatus = this.isHoliday(endDate);


            if (endDateStatus === 'holiday') {
                // 如果是节假日，直接跳过
                console.log(endDate.format("YYYY-MM-DD") + ' is holiday')
            } else {
                // 不是节假日，也不是加班日，就是普通的工作日，只要不是周末就获取长度
                if (!this.isWeekend(endDate) || endDateStatus === 'workday') {
                    if (endTime === endTimeList[0].value) {
                        num += 0.5;
                    } else if (endTime === endTimeList[1].value) {
                        num += 1;
                    }
                }
            }


            // 9号和10号之间的 diff 只有1，所以根本不会走循环，上面的两个非周末判断没毛病就够了

            for (let i = 1; i < diff; i++) {
                const date = startDate.clone().add(i, 'days');


                const status = this.isHoliday(date)

                if (status === 'holiday') {
                    console.log(date.format("YYYY-MM-DD") + ' is holiday')
                } else {
                    if (!this.isWeekend(date) || status === 'workday') {
                        num += 1;
                    }
                }


            }

            if (num === 0) {
                this.$message.error('请选择工作日')
            }
            return num;

        },

        isHoliday(date) {

            let status = '';

            loop: for (let i = 0; i < this.holiday.length; i++) {
                const item = this.holiday[i]
                if (Array.isArray(item.data)) {
                    for (let j = 0; j < item.data.length; j++) {
                        const element = item.data[j];


                        if (date.isSameOrAfter(element.startHoliday, 'day') && date.isSameOrBefore(element.endHoliday, 'day')) {
                            status = 'holiday';
                            break loop;
                        }


                        for (let x = 0; x < element.workday.length; x++) {
                            if (date.isSame(element.workday[x], 'day')) {
                                status = 'workday'
                                break loop;
                            }

                        }
                    }
                }
            }

            console.log('status', status)
            return status;

        },

        isWeekend(date) {
            return date.day() === 0 || date.day() === 6;
        },


        getLength({ diff, startTime, endTime }) {
            let num = diff - 1;

            if (startTime === startTimeList[0].value) {
                num += 1;
            } else if (startTime === startTimeList[1].value) {
                num += 0.5;
            }

            if (endTime === endTimeList[0].value) {
                num += 0.5;
            } else if (endTime === endTimeList[1].value) {
                num += 1;
            }

            return num;
        },

        isRealHoliday(date) {
            let flag = false;

            loop: for (let i = 0; i < this.holiday.length; i++) {
                const item = this.holiday[i];
                if (Array.isArray(item.data)) {
                    for (let j = 0; j < item.data.length; j++) {
                        const element = item.data[j];
                        // console.log("element", element);
                        if (Array.isArray(element.realHoliday)) {
                            for (let x = 0; x < element.realHoliday.length; x++) {
                                const real = element.realHoliday[x]
                                if (
                                    date.isSame(real, "days")
                                ) {
                                    flag = true;
                                    break loop;
                                }
                            }
                        }
                    }
                }
            }
            return flag;
        },

        // 产假的判断长度逻辑，包含周末，但不包含法定节假日，而且是真正的法定节假日，不是国庆7天，而是国庆3天，就是只跳过这核心的几天，其他的一概不跳过
        getLengthWithoutRealHoliday({ diff, startDate, endDate, startTime, endTime }) {

            let num = 0;

            const startDateStatus = this.isRealHoliday(startDate);

            if (startDateStatus) {
                // 如果是真正的节假日，直接跳过
                console.log(startDate.format("YYYY-MM-DD") + 'is holiday')
            } else {
                if (startTime === startTimeList[0].value) {
                    num += 1;
                } else if (startTime === startTimeList[1].value) {
                    num += 0.5;
                }
            }

            const endDateStatus = this.isRealHoliday(endDate);


            if (endDateStatus) {
                // 如果是真正的节假日，直接跳过
                console.log(endDate.format("YYYY-MM-DD") + ' is holiday')
            } else {
                if (endTime === endTimeList[0].value) {
                    num += 0.5;
                } else if (endTime === endTimeList[1].value) {
                    num += 1;
                }
            }


            for (let i = 1; i < diff; i++) {
                const date = startDate.clone().add(i, 'days');

                const status = this.isRealHoliday(date)

                if (status) {
                    console.log(date.format("YYYY-MM-DD") + ' is holiday')
                } else {
                    num += 1;
                }
            }

            if (num === 0) {
                this.$message.error('请选择非法定节假日')
            }
            return num;

        },


        // 获取因公外出的长度
        getOutLen({
            startDate,
            startTime,
            endDate,
            endTime,

        }) {

            if (startDate && startTime && endDate && endTime) {

                console.log(startDate.format("YYYY-MM-DD"), startTime, endDate.format("YYYY-MM-DD"), endTime)

                if (endDate.isBefore(startDate)) {
                    this.$message.error('结束日期不得早于开始日期')
                    return;
                }

                const startTimeStamp = moment(startDate.format("YYYY-MM-DD") + ' ' + startTime).unix();
                const endTimeStamp = moment(endDate.format("YYYY-MM-DD") + ' ' + endTime).unix();

                const value = endTimeStamp - startTimeStamp;

                if (value <= 0) {
                    this.$message.error("结束时间不得早于开始时间")
                    return;
                }

                console.log('value', value)
                return value / 3600;

                // if (endDate.isSame(startDate, 'day')) {
                //     console.log(moment(startDate.format('YYYY-MM-DD') + ' ' + startTime).format("YYYY-MM-DD HH:mm:ss"))
                //     const startTimeStamp = moment(startDate.format("YYYY-MM-DD") + ' ' + startTime).unix();
                //     const endTimeStamp = moment(endDate.format("YYYY-MM-DD") + ' ' + endTime).unix();

                //     const value = endTimeStamp - startTimeStamp;

                //     if (value <= 0) {
                //         this.$message.error("结束时间不得早于开始时间")
                //         return;
                //     }

                //     console.log('value', value)
                //     return value / 3600;

                // }


                // let diff = endDate.diff(startDate, 'days');
                // console.log('original diff', diff)

                // if (diff > 0) {
                //     // 公出和出差 不跳过周末和节假日，这期间算是正常公出和出差
                //     let num = diff - 1;

                //     const startTimeStamp = moment(startDate.format("YYYY-MM-DD") + ' ' + startTime).unix();
                //     const endTimeStamp = moment(startDate.format("YYYY-MM-DD") + ' ' + endTime).unix();

                //     const value = Math.abs(endTimeStamp - startTimeStamp);

                //     return num * 24 + (value / 3600);


                // }


            }
        },




    }
}