<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-button type="primary" @click="visible = true">考勤规则</a-button>
        </a-space>
      </template>
    </Pane>
    <div class="container">
      <Menu :leaveList="leaveList" :overtimeList="overtimeList" :daysoffList="daysoffList" @refresh="getList" />
      <Calendar :list="leaveList" :overtimeList="overtimeList" :daysoffList="daysoffList" @change="(payload) => (date = payload)" />
    </div>

    <a-modal title="考勤规则" :visible="visible" @cancel="visible = false" :footer="null">
      <h2>打卡</h2>
      <p>上班时间是09:00，下班时间是17:30，请及时打卡。</p>
      <p>
        上班请在
        <span style="color: red">进门</span> 摄像头处扫脸打卡，下班请在
        <span style="color: red">出门</span> 摄像头处扫脸打卡。
      </p>
      <p style="color: red">当天考勤请在次日凌晨6时之前完成，不再强制当天12点之前打卡了。</p>

      <h2>考勤异常</h2>
      <p>若考勤异常，可点击下方日历申请补卡，每月有两次机会，当月有效，同一天不可补卡两次。</p>

      <h2>出差</h2>
      <p>在集团网报系统上报销时，需要提供出差单，可在本系统工作台页面-左上角-待办事项-打开详情页面-右上角-点击打印按钮，获取出差单。</p>

      <h2>同仁街31号App</h2>
      <p>App 有可能跟瓴云版本不一致，从而导致数据不一致，以瓴云为准，瓴云的开发优先级更高，更新更频繁。App 如有更新会有弹窗，请按照提示安装更新包即可。</p>

      <h2>备注</h2>

      <p>考勤上有任何疑问请联系人力资源部伍佳，联系方式：18551845966。</p>
      <p>软硬件系统上有任何问题请联系高质量发展研究院倪辰，联系方式：15251827036。</p>
    </a-modal>
  </div>
</template>

<script>
import Menu from "./components/menu.vue";
import Calendar from "./components/calendar.vue";
import moment from "moment";

import { fetchUserLeave,fetchAdjustList } from "@/api/human-resources/clock";
import { mapState } from "vuex";
export default {
  components: {
    Menu,
    Calendar
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  data() {
    return {
      overtimeList: [],
      daysoffList: [],
      leaveList: [],
      visible: false,
      date: {}
    };
  },

  watch: {
    date: {
      handler() {
        this.getList();
      },
      deep: true
    }
  },

  methods: {
    // 获取审批数据
    getList() {
      if (!this.date.year) return;
      const d = new Date(this.date.year, this.date.month - 1);
      const m = moment(d);
      const startTime = m.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime = m.endOf("month").format("YYYY-MM-DD") + " 23:59:59";
      fetchUserLeave({
        startTime,
        endTime,
        statusList: ["reviewing", "approved"]
      }).then(res => {
        if (Array.isArray(res)) {
          this.leaveList = Object.freeze(
            res.filter(item => item.optType !== "cancel")
          );
        }
      });
      fetchAdjustList({
        userId: this.user.uuid,
        startTime:startTime,
        endTime:endTime,
        type: 'overtime'
      }).then((res) => {
        this.overtimeList = res
      })
      fetchAdjustList({
        userId: this.user.uuid,
        startTime:startTime,
        endTime:endTime,
        type: 'days_off'
      }).then((res) => {
        this.daysoffList = res
      })
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  background-color: #f2f7ff;
}
</style>