export const startTimeList = [{
    label: '09:00',
    value: '09:00:00',
}, {
    label: '13:30',
    value: '13:30:00'
}];

export const endTimeList = [{
    label: '12:00',
    value: '12:00:00',
}, {
    label: '17:30',
    value: '17:30:00',
}];
export function getOvertimeList() {
    const result = []
    for (let i = 6; i < 24; i++) {

        let str = i;
        if (i < 10) {
            str = '0' + i;
        }
        result.push({
            label: str + ':00',
            value: str + ':00:00',
        }, {
            label: str + ':30',
            value: str + ':30:00',
        })
    }
    result.push({
        label: '24:00',
        value: '24:00:00',
    })
    return result;
}
export function getOutTimeList() {
    const result = []
    for (let i = 6; i < 23; i++) {

        let str = i;
        if (i < 10) {
            str = '0' + i;
        }
        result.push({
            label: str + ':00',
            value: str + ':00:00',
        }, {
            label: str + ':30',
            value: str + ':30:00',
        })
    }

    return result;
}